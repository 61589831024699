import { createApi } from '@reduxjs/toolkit/query/react'
import { DocumentEntity, GetTemplatesResponse } from 'redux-store/models'
import { baseQueryWithReauth } from 'redux-store/services/services.utils'
import { ApiSuccessResponse, DocumentTypes } from 'types'

import {
  CreateDocFromTemplateRequest,
  CreateDocFromTemplateResponse,
  GetCommentsResponse,
  PostCommentRequest,
  PostCommentResponse,
  UpdateDocMetadataRequest,
  DownloadUrl,
  GetDownloadUrlRequest,
  GetWopiResponse,
} from './documentApi.types'

export const documentApi = createApi({
  reducerPath: 'documentApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getDocumentById: builder.query<ApiSuccessResponse<DocumentEntity>, string>({
      query: (id) => `documents/${id}`,
    }),
    getDocumentTypes: builder.query<
      ApiSuccessResponse<DocumentTypes[]>,
      { skip_guideline: boolean | undefined }
    >({
      query: ({ skip_guideline }) =>
        `documents/types?skip_guideline=${skip_guideline === true ? '1' : '0'}`,
    }),
    getTemplates: builder.query<GetTemplatesResponse, null>({
      query: () => 'templates',
    }),
    getComments: builder.query<GetCommentsResponse, string>({
      query: (docId) => `/documents/${docId}/comments`,
    }),
    getWopiUrl: builder.query<GetWopiResponse, string>({
      query: (docId) => `/wopi/${docId}`,
    }),
    getDocumentFileDownloadEntityById: builder.query<
      ApiSuccessResponse<DownloadUrl>,
      GetDownloadUrlRequest
    >({
      query: ({ id, format, rs }) => {
        const searchParams = []
        if (format) searchParams.push(`format=${format}`)
        if (rs) searchParams.push(`rs=${rs}`)
        return `documents/${id}/download-url${
          searchParams.length > 0 ? `?${searchParams.join('&')}` : ''
        }`
      },
    }),
    postComment: builder.mutation<PostCommentResponse, PostCommentRequest>({
      query: ({ comment, documentId }) => ({
        url: `/documents/${documentId}/comments`,
        method: 'POST',
        body: { comment },
      }),
    }),

    updateDocMetadata: builder.mutation<null, UpdateDocMetadataRequest>({
      query: ({ documentId, body }) => ({
        method: 'PUT',
        url: `documents/${documentId}`,
        body,
      }),
    }),
    createDocFromTemplate: builder.mutation<
      CreateDocFromTemplateResponse,
      CreateDocFromTemplateRequest
    >({
      query: (body) => ({
        url: 'documents/from-template',
        method: 'POST',
        body,
      }),
    }),
  }),
})

export const { useGetDocumentByIdQuery } = documentApi
