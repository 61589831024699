import { PracticeQueryParams } from 'constants/application-constants'

import { useEffect } from 'react'

import clsx from 'clsx'
import {
  Card,
  LoadingIndicator,
  PageTitle,
  Section,
  SectionHeader,
} from 'components/common'
import { DocumentsTable } from 'components/features/Documents/components'
import { permissionsActions } from 'components/features/Other/api/permissionsSlice'
import {
  useAppDispatch,
  useAppSelector,
  useFrontOfficeCheck,
  usePermissions,
  useToggle,
} from 'hooks'
import { t } from 'i18next'
import { useParams, useSearchParams } from 'react-router-dom'

import MainDocumentCard from './MainDocumentCard'
import MetadataDetailCard from './MetadataDetailCard'
import styles from './PracticeInformation.module.scss'
import RevisionCycleCard from './RevisionCycleCard'
import { RevisionStatus } from '../../../../../redux-store/models'
import { practicesApi, selectCurrentRevision } from '../../api'
import { selectMinimalPractice } from '../../api/minimalPracticeSlice'
import { selectPractice } from '../../api/practiceSlice'
import PracticeSidebar from '../PracticeSidebar/PracticeSidebar'

const PracticeInformation = () => {
  const dispatch = useAppDispatch()
  const [isModalOpen, toggleSetOutdated] = useToggle(false)
  const currentRevision = useAppSelector(selectCurrentRevision)
  const isFrontOffice = useFrontOfficeCheck()
  const practice = useAppSelector(
    isFrontOffice ? selectMinimalPractice : selectPractice
  )
  const [searchParams] = useSearchParams()
  const { id = '' } = useParams()

  // TODO: move active language information to store
  const activeLanguageCode =
    searchParams.get(PracticeQueryParams.METADATA_LANGUAGE) ||
    currentRevision?.metadata[0].language.code

  const currentMetadata = currentRevision?.metadata.find(
    ({ language }) => activeLanguageCode === language.code
  )

  const httpGetPracticeState = isFrontOffice
    ? practicesApi.endpoints.getMinimalPracticeById.useQueryState(id)
    : practicesApi.endpoints.getPracticeById.useQueryState(id)

  const httpGetRevision = practicesApi.useGetRevisionByIdQuery(
    currentRevision?.id ?? '',
    {
      skip: !currentRevision,
      refetchOnMountOrArgChange: !isFrontOffice,
    }
  )

  usePermissions(id) // Ensure permissions for guideline get fetched

  useEffect(() => {
    dispatch(permissionsActions.setEnforcedGuideline(!!id ? id : null))

    return () => {
      dispatch(permissionsActions.setEnforcedGuideline(null))
    }
  }, [dispatch, id])

  const isLoading =
    httpGetPracticeState.isFetching || httpGetRevision.isFetching
  const isError =
    !isLoading && (httpGetPracticeState.isError || httpGetRevision.isError)

  if (isLoading) {
    return (
      <div className={styles.loader}>
        <LoadingIndicator />
      </div>
    )
  }

  // ERROR/ EMPTY STATE
  if (!isError && !currentMetadata) {
    return null
  }

  if (isError) {
    // @ts-ignore
    const practiceErrorStatus = httpGetPracticeState?.error?.status
    // @ts-ignore
    const revisionErrorStatus = httpGetRevision?.error?.status

    return (
      <div className={styles.root}>
        <div className={styles.body}>
          <Section>
            <PageTitle title={t('practice.loadingErrorTitle')} />
            {(practiceErrorStatus === 404 || revisionErrorStatus === 404) && (
              <div>{t('practice.loadingError404')}</div>
            )}
            {practiceErrorStatus !== 404 && revisionErrorStatus !== 404 && (
              <div>{t('practice.loadingError')}</div>
            )}
          </Section>
        </div>
      </div>
    )
  }

  const data = httpGetPracticeState.data?.data

  var mainDocMetadata = null
  var status = currentRevision?.status

  if (
    !!httpGetRevision.data?.data?.main_document &&
    httpGetRevision.data.data.main_document.length > 0 &&
    !!status
  ) {
    // Access proper Metadata value with fallbacks
    let temp
    const mainDocument = httpGetRevision.data?.data?.main_document[0]

    // depending on the revision status, choose the correct metadata to display
    // todo: extract this into a method
    let documentMetadataAccessor:
      | 'latest_approved_version'
      | 'current_approvalish_version'
      | 'latest_draft_version'

    switch (currentRevision?.status) {
      case RevisionStatus.draft:
      case RevisionStatus.precheck:
        documentMetadataAccessor = 'latest_draft_version'
        break

      case RevisionStatus.approval:
      case RevisionStatus.preapproval:
        documentMetadataAccessor = 'current_approvalish_version'
        break

      case RevisionStatus.published:
        documentMetadataAccessor = 'latest_approved_version'
        break

      default:
        documentMetadataAccessor = 'latest_approved_version'
    }

    temp = mainDocument && mainDocument[documentMetadataAccessor]?.metadata
    mainDocMetadata = temp && 0 in temp ? temp[0] : undefined
  }

  const wrapperCss = clsx({ [styles.wrapper]: !isFrontOffice })

  return (
    <div className={styles.root}>
      <div className={styles.body}>
        {currentMetadata && (
          <Section>
            <PageTitle
              title={currentMetadata.title}
              description={currentMetadata.subject}
            />
            <div className={wrapperCss}>
              {practice && data && (
                <MetadataDetailCard
                  metadata={currentMetadata}
                  author={data.author}
                  practice={practice}
                  isModalOpen={isModalOpen}
                  toggleModal={toggleSetOutdated}
                />
              )}
              {!isFrontOffice && currentRevision && (
                <RevisionCycleCard
                  practice={practice}
                  currentRevision={currentRevision}
                  toggleModal={toggleSetOutdated}
                />
              )}
            </div>
          </Section>
        )}
        {httpGetRevision.isFetching && <LoadingIndicator />}

        {!httpGetRevision.isFetching && httpGetRevision.isSuccess && (
          <>
            {mainDocMetadata &&
              !!httpGetRevision?.data?.data?.main_document &&
              !!httpGetRevision?.data?.data?.main_document[0]?.id && (
                <Section>
                  <SectionHeader title={t('practice.mainDocument')} />

                  <MainDocumentCard
                    document={mainDocMetadata}
                    docId={httpGetRevision.data.data.main_document[0].id}
                  />
                </Section>
              )}

            {!!httpGetRevision?.data?.data?.documents?.length && (
              <Section>
                <SectionHeader title={t('practice.attachments')} />
                <Card hasTable>
                  <Card.Body className={styles.docBody}>
                    <DocumentsTable
                      documents={httpGetRevision.data.data.documents}
                      status={status}
                    />
                  </Card.Body>
                </Card>
              </Section>
            )}
          </>
        )}
      </div>
      <PracticeSidebar />
    </div>
  )
}

export default PracticeInformation
