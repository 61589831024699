import { Direction } from 'constants/enums/directions'

import clsx from 'clsx'

import styles from './Tooltip.module.scss'
import Typography from '../Typography/Typography'

interface TooltipProps {
  /**
   * The text you want to display in tooltip
   */
  content: string
  /**
   * The component you want to attach tooltip to
   */
  children: React.ReactNode
  /**
   * What should be position of the tooltip relative to the component in children prop
   */
  position: Direction
  /**
   * What should be the alignment of the tooltip relative to the position (think row alignment)
   */
  boxAlignment?: 'start' | 'center' | 'end'
  /**
   * color of the tooltip
   */
  color?: 'light' | 'dark'
  /**
   * size of the tool tip text
   */
  size?: 'x-small' | 'small' | 'medium'
  /**
   * additional classes
   */
  className?: string
}

const Tooltip = ({
  children,
  content,
  position,
  boxAlignment = 'center',
  color = 'light',
  className = '',
  size = 'medium',
}: TooltipProps) => {
  const css = clsx(
    styles.text,
    styles[`co-${color}`],
    styles[`po-${position}`],
    styles[`ba-${boxAlignment}`],
    styles[`sz-${size}`]
  )

  return (
    <div className={clsx(styles.root, className)}>
      {children}
      <Typography element="span" size={size} className={css}>
        {content}
      </Typography>
    </div>
  )
}

export default Tooltip
