import { useRef, useState } from 'react'

import clsx from 'clsx'
import { Button, Card, Modal, Typography } from 'components/common'
import { addToast } from 'components/features/Other/api'
import { FTextField } from 'components/formik-factory'
import { Formik } from 'formik'
import { useAppDispatch } from 'hooks'
import { t } from 'i18next'
import Quill from 'quill'
import { X } from 'react-feather'

import 'quill/dist/quill.snow.css'

import styles from './AnnouncementEdit.module.scss'
import { announcementValidationSchema } from './AnnouncementEdit.validationScema'
import { toDeltaFormat } from '../../../../../helpers'
import { Announcement } from '../../../../../types/announcement'
import Editor from '../../../../common/Editor/Editor'
import { announcementsApi } from '../../api/announcementsApi'

interface AnnouncementEditProps {
  data: Announcement
  isModalOpen: boolean
  toggleModal: any
}

const AnnouncementEdit = ({
  data,
  isModalOpen,
  toggleModal,
}: AnnouncementEditProps): JSX.Element => {
  const dispatch = useAppDispatch()
  const quillRef = useRef<Quill>(null)
  const [updateAnnouncement] = announcementsApi.useUpdateAnnouncementMutation()
  const [httpGetAnnouncements] = announcementsApi.useLazyGetAnnouncementsQuery()
  const [content, setContent] = useState<{ ops: [] }>()
  const [, setLastChange] = useState()

  const handleSubmit = async (values: { title: string }) => {
    if (!data.id) return

    try {
      const response = await updateAnnouncement({
        id: data.id,
        data: {
          title: values.title,
          content: content ? JSON.stringify(content.ops) : [],
        },
      })

      if (response.hasOwnProperty('error')) {
        dispatch(
          addToast({
            color: 'error',
            // @ts-ignore
            content: response?.error?.data?.message
              ? // @ts-ignore
                response?.error?.data?.message
              : t('error.general'),
          })
        )
      } else {
        httpGetAnnouncements(null)
        dispatch(addToast({ color: 'success', content: t('home.updateSaved') }))
      }
    } catch (err) {
      dispatch(addToast({ color: 'error', content: t('error.general') }))
    } finally {
      toggleModal(false)
    }
  }

  return (
    <>
      <Modal open={isModalOpen} handleClose={() => {}}>
        <Formik
          initialValues={{ title: data.title }}
          onSubmit={() => {}}
          validationSchema={announcementValidationSchema}
        >
          {({ isValid, values }) => (
            <Card className={styles.card}>
              <Card.Header>
                <Button
                  size="x-small"
                  icon={<X />}
                  variant="text"
                  iconLabel={t('metadata.closeAria') ?? ''}
                  onClick={() => toggleModal(false)}
                  className={styles.closeButton}
                />
                <Typography type="display" size="x-small" fontWeight="bold">
                  {t('home.editUpdate')}
                </Typography>
              </Card.Header>
              <Card.Body>
                <div className={styles.group}>
                  <FTextField
                    name="title"
                    id="title"
                    label={t('home.updateTitle') ?? ''}
                  />
                </div>
                <div className={clsx(styles.group, styles.textArea)}>
                  <Editor
                    ref={quillRef}
                    readOnly={false}
                    defaultValue={toDeltaFormat(data.content)}
                    onTextChange={setContent}
                    onSelectionChange={setLastChange}
                    className={styles.editorMaxHeight}
                  />
                </div>
              </Card.Body>
              <Card.Footer className={styles.footer}>
                <Button variant="text" onClick={() => toggleModal()}>
                  {t('approvalDetail.cancel')}
                </Button>
                <Button
                  onClick={() => handleSubmit(values)}
                  disabled={!isValid}
                  color="primary"
                >
                  {t('home.submitUpdate')}
                </Button>
              </Card.Footer>
            </Card>
          )}
        </Formik>
      </Modal>
    </>
  )
}

export default AnnouncementEdit
