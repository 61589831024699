import { OfficeName, Permissions } from 'constants/application-constants'

import { lazy, useMemo } from 'react'

import { LoginPage, SsoPage } from 'components/features/Auth/routes'
import { OverviewPage } from 'components/features/Dashboard/routes'
import DocumentPage from 'components/features/Documents/routes/DocumentsPage/DocumentsPage'
import { NoPermissionModal } from 'components/features/Other/routes'
import {
  CollaboratorModal,
  FrontOfficePractices,
  Practices,
  PreCheckModal,
} from 'components/features/Practices/routes'
import EditGuideline from 'components/features/Practices/routes/EditGuideline/EditGuideline'
import { UserOnboardingPage } from 'components/features/User/routes'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import { BackgroundLocationState } from 'types'

import ProtectedRoute from './ProtectedRoute'

const SearchPage = lazy(
  () => import('../features/Search/routes/SearchPage/SearchPage')
)

const FavoritePage = lazy(
  () => import('../features/Favorites/routes/favorites/FavoritePage')
)

const FrontOfficePractice = lazy(
  () =>
    import(
      'components/features/Practices/routes/FrontOfficePractice/FrontOfficePractice'
    )
)
const BackOfficePractice = lazy(
  () =>
    import(
      'components/features/Practices/routes/BackOfficePractice/BackOfficePractice'
    )
)
const MetadataDetailsModal = lazy(
  () =>
    import(
      'components/features/Practices/routes/MetadataDetailsModal/MetadataDetailsModal'
    )
)
const DocumentMetadataModal = lazy(
  () =>
    import(
      'components/features/Documents/routes/DocumentMetadataModal/DocumentMetadataModal'
    )
)
const DocumentReplace = lazy(
  () =>
    import(
      'components/features/Documents/routes/DocumentReplace/DocumentReplace'
    )
)

const DocumentEdit = lazy(
  () => import('components/features/Documents/routes/DocumentEdit/DocumentEdit')
)

const NewGuideline = lazy(
  () => import('components/features/Practices/routes/NewGuideline/NewGuideline')
)

const NewDocument = lazy(
  () => import('components/features/Documents/routes/NewDocument/NewDocument')
)

const UploadPage = lazy(
  () => import('components/features/Upload/routes/UploadPage/UploadPage')
)

const DocumentEditWopi = lazy(
  () =>
    import(
      'components/features/Documents/routes/DocumentEditWopi/DocumentEditWopi'
    )
)

const Routing = () => {
  const location = useLocation()

  const locationState = useMemo(
    () => location.state as BackgroundLocationState,
    [location.state]
  )

  return (
    <>
      <Routes location={locationState?.backgroundLocation || location}>
        <Route path="/">
          <Route
            index
            element={
              <ProtectedRoute>
                <FrontOfficePractices />
              </ProtectedRoute>
            }
          />

          <Route
            path="practices"
            element={
              <ProtectedRoute
                permissionsRequired={[Permissions.ACCESS_BACKOFFICE]}
              >
                <Practices />
              </ProtectedRoute>
            }
          />
        </Route>

        <Route
          path="upload"
          element={
            <ProtectedRoute permissionsRequired={[Permissions.CREATE_DOCUMENT]}>
              <UploadPage />
            </ProtectedRoute>
          }
        />

        <Route
          path="new-document"
          element={
            <ProtectedRoute permissionsRequired={[Permissions.CREATE_DOCUMENT]}>
              <NewDocument />
            </ProtectedRoute>
          }
        />
        <Route
          path="new-guideline"
          element={
            <ProtectedRoute permissionsRequired={[Permissions.CREATE_PRACTICE]}>
              <NewGuideline />
            </ProtectedRoute>
          }
        />

        <Route
          path={`${OfficeName.FRONT}/practices/:id`}
          element={
            <ProtectedRoute>
              <FrontOfficePractice />
            </ProtectedRoute>
          }
        >
          <Route
            path="details"
            element={
              <ProtectedRoute>
                <MetadataDetailsModal />
              </ProtectedRoute>
            }
          />
        </Route>

        <Route
          path={`${OfficeName.BACK}/practices/:id`}
          element={
            <ProtectedRoute permissionsRequired={[Permissions.READ_PRACTICE]}>
              <BackOfficePractice />
            </ProtectedRoute>
          }
        >
          <Route
            path="details"
            element={
              <ProtectedRoute>
                <MetadataDetailsModal />
              </ProtectedRoute>
            }
          />

          <Route
            path="pre-check"
            element={
              <ProtectedRoute>
                <PreCheckModal />
              </ProtectedRoute>
            }
          />
          <Route
            path="collaborate"
            element={
              <ProtectedRoute>
                <CollaboratorModal />
              </ProtectedRoute>
            }
          />
        </Route>
        <Route
          path={`${OfficeName.BACK}/practices/:id/upload`}
          element={
            <ProtectedRoute permissionsRequired={[Permissions.CREATE_DOCUMENT]}>
              <UploadPage limitGuidelineToParam />
            </ProtectedRoute>
          }
        />

        <Route
          path={`${OfficeName.BACK}/practices/:id/edit`}
          element={
            <ProtectedRoute permissionsRequired={[Permissions.UPDATE_PRACTICE]}>
              <EditGuideline />
            </ProtectedRoute>
          }
        />

        <Route
          path={`${OfficeName.BACK}/documents/:documentId/wopi`}
          element={
            <ProtectedRoute permissionsRequired={[Permissions.UPDATE_DOCUMENT]}>
              <DocumentEditWopi />
            </ProtectedRoute>
          }
        />

        <Route
          path={`${OfficeName.BACK}/documents/:documentId`}
          element={
            <ProtectedRoute>
              <DocumentPage />
            </ProtectedRoute>
          }
        >
          <Route
            path="details"
            element={
              <ProtectedRoute>
                <DocumentMetadataModal />
              </ProtectedRoute>
            }
          />
          <Route
            path="replace"
            element={
              <ProtectedRoute
                permissionsRequired={[Permissions.UPDATE_DOCUMENT]}
              >
                <DocumentReplace />
              </ProtectedRoute>
            }
          />
          <Route
            path="edit"
            element={
              <ProtectedRoute
                permissionsRequired={[Permissions.UPDATE_DOCUMENT]}
              >
                <DocumentEdit />
              </ProtectedRoute>
            }
          />
        </Route>
        <Route
          path={`${OfficeName.FRONT}/documents/:documentId`}
          element={
            <ProtectedRoute>
              <DocumentPage />
            </ProtectedRoute>
          }
        >
          <Route
            path="details"
            element={
              <ProtectedRoute>
                <DocumentMetadataModal />
              </ProtectedRoute>
            }
          />
        </Route>

        <Route
          path="overview"
          element={
            <ProtectedRoute permissionsRequired={[Permissions.VIEW_DASHBOARD]}>
              <OverviewPage />
            </ProtectedRoute>
          }
        />

        <Route
          path="search"
          element={
            <ProtectedRoute>
              <SearchPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="favorites"
          element={
            <ProtectedRoute>
              <FavoritePage />
            </ProtectedRoute>
          }
        />
        <Route
          path="login"
          element={
            <ProtectedRoute isNonAuthOnly>
              <LoginPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="sso"
          element={
            <ProtectedRoute isNonAuthOnly>
              <SsoPage />
            </ProtectedRoute>
          }
        />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>

      {locationState?.backgroundLocation && (
        // For contextual modals. Reference: https://reactrouter.com/docs/en/v6/examples/modal
        <Routes>
          <Route
            path="user/onboard"
            element={
              <ProtectedRoute>
                <UserOnboardingPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/forbidden"
            element={
              <ProtectedRoute>
                <NoPermissionModal />
              </ProtectedRoute>
            }
          />
        </Routes>
      )}
    </>
  )
}

export default Routing
