import { OfficeName } from 'constants/application-constants'

import { Avatar, Card, Table, Typography } from 'components/common'
import { DocumentIcon } from 'components/features/Other/components'
import { formatDistance } from 'date-fns'
import { t } from 'i18next'
import { useTranslation } from 'react-i18next'
import { Link, LinkProps } from 'react-router-dom'
import { PracticeFeedback } from 'types'

import styles from './LatestFeedbackTable.module.scss'

interface LatestFeedbackTableProps {
  data: PracticeFeedback[]
  labelledBy?: string
}

const caseT =
  <S extends PropertyKey, T>(options: Record<S, T>, def: T) =>
  (choice: S) =>
    Object.hasOwn(options, choice) ? options[choice] : def

const to = (item: PracticeFeedback, locale: string) => {
  const searchParams = (target: string) => `rs=${target}&ml=${locale}`
  return caseT<string, LinkProps['to']>(
    {
      document: {
        pathname: `/${OfficeName.BACK}/documents/${item.parent.id}`,
        hash: 'comments',
      },
      approvalprocess: {
        pathname: `/${OfficeName.BACK}/practices/${item.practice_id}`,
        search: searchParams('approval'),
        hash: 'comments',
      },
      practice: {
        pathname: `/${OfficeName.BACK}/practices/${item.practice_id}`,
        search: searchParams('published'),
        hash: 'comments',
      }
    },
    {
      pathname: `/${OfficeName.BACK}/practices/${item.practice_id}`,
      search: searchParams('draft'),
      hash: 'comments',
    }
  )(item.parent.type)
}

const LatestFeedbackTable = ({
  data,
  labelledBy,
}: LatestFeedbackTableProps): JSX.Element => {
  const { i18n } = useTranslation()
  return (
    <Card hasTable>
      <Card.Body>
        <Table aria-labelledby={labelledBy} className={styles.root} isFullWidth>
          <Table.Head>
            <Table.Row isHeadRow>
              <Table.Cell>{t('latestFeedbackTable.content')}</Table.Cell>
              <Table.Cell>{t('latestFeedbackTable.assignee')}</Table.Cell>
              <Table.Cell>{t('latestFeedbackTable.comments')}</Table.Cell>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {data
              .filter((item) => !!item.parent.id)
              .map((item) => (
                <Table.Row key={`latest-feedback-row-${item.id}`} isClickable>
                  <Table.Cell isLead>
                    <Link
                      to={to(item, i18n.language)}
                      aria-label={item.parent.name + ', go to detailpage'}
                    />
                    <div className={styles.name}>
                      <DocumentIcon
                        type={item.parent.type}
                        avatarProps={{
                          size: 'medium',
                        }}
                      />
                      <Typography>{item.parent.name}</Typography>
                    </div>
                  </Table.Cell>
                  <Table.Cell>
                    <div className={styles.contentAuthor}>
                      <Avatar
                        content={item.author.initials}
                        size="small"
                        src={item.author.avatar_public_path ?? ''}
                      />
                      <div className={styles.contentDate}>
                        <Typography size="small" fontWeight="medium">
                          {item.author.name}
                        </Typography>
                        <Typography size="small">
                          {formatDistance(
                            new Date(item.created_at),
                            new Date(),
                            {
                              addSuffix: true,
                            }
                          )}
                        </Typography>
                      </div>
                    </div>
                  </Table.Cell>
                  <Table.Cell className={styles.content}>
                    <Typography size="small" color="label">
                      {item.content}
                    </Typography>
                  </Table.Cell>
                </Table.Row>
              ))}
          </Table.Body>
        </Table>
      </Card.Body>
    </Card>
  )
}

export default LatestFeedbackTable
